textarea,
input[type="text"],
input[type="number"],
input[type="tel"],
input[type="email"],
input[type="password"],
select {
   background: #ffffff;
   border: 0.5px solid #e0e0e0;
   box-shadow: 0px 0px 29px rgba(0, 0, 0, 0.05);
   border-radius: 11px;
   height: 60px;
   line-height: 60px;
   padding: 0 15px;
   box-sizing: border-box;
   font-size: 18px;

   &::placeholder {
      color: rgba(map-get($colors, base), 0.6);
   }
}

textarea {
   height: 150px;
   line-height: 20px;
   padding: 20px;

   @media (min-width: 1900) {
      height: 200px;
   }
}

.form-check {
   display: block;
   min-height: 1.5rem;
   padding-left: 1.5em;
   margin-bottom: 0.825rem;
}

.form-check-input[type=radio] {
   border-radius: 50%;
}

.form-check .form-check-input {
   float: left;
   margin-left: -1.5em;
}

.form-check-input {
   width: 1em;
   height: 1em;
   margin-top: 0.25em;
   vertical-align: top;
   background-color: #fff;
   background-repeat: no-repeat;
   background-position: center;
   background-size: contain;
   border: 1px solid rgba(0,0,0,.25);
}

.form-check-label {
   display: inline-block;
}

//*******************************************************
// Toggle Switch
//*******************************************************

/* Hide the default checkbox */
.form-check-input[type="checkbox"] {
   display: none;
}

/* Create a custom checkbox */
.form-check-input[type="checkbox"] + .form-check-label {
   position: relative;
   padding-left: 90px;
   cursor: pointer;
   line-height: 40px;
}

.form-check-input[type="checkbox"] + .form-check-label:before {
   content: '';
   position: absolute;
   left: 0;
   width: 80px;
   height: 40px;
   border-radius: 25px;
   background: red;
   transition: background 0.3s;
}

.form-check-input[type="checkbox"] + .form-check-label:after {
   content: 'off';
   position: absolute;
   left: 3px;
   top: 3px;
   width: 34px;
   height: 34px;
   border-radius: 50%;
   background: #fff;
   transition: left 0.3s;
   font-size: 11px;
   display: flex;
   align-items: center;
   justify-content: center;
}

/* Style when checkbox is checked */
.form-check-input[type="checkbox"]:checked + .form-check-label:before {
   background: #4CAF50;
}

.form-check-input[type="checkbox"]:checked + .form-check-label:after {
   left: 43px;
   content: 'on';
}
