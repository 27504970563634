@each $name, $value in $colors {
   .bg-#{$name} {
      background-color: $value !important;
   }
}

.bg-gradient {
   background: rgb(186, 229, 238);
   background: linear-gradient(
      0deg,
      rgba(186, 229, 238, 1) 0%,
      rgba(209, 221, 188, 1) 48%,
      rgba(175, 146, 242, 1) 100%
   );
}

.bg-gradient-purple {
   background: rgb(51, 22, 95);
   background: linear-gradient(
      180deg,
      rgba(51, 22, 95, 1) 0%,
      rgba(111, 51, 161, 1) 48%,
      rgba(163, 77, 218, 1) 100%
   );
}

.bg-gradient-blue {
   background: rgb(15, 21, 95);
   background: linear-gradient(
      180deg,
      rgba(15, 21, 95, 1) 0%,
      rgba(26, 41, 147, 1) 48%,
      rgba(37, 59, 196, 1) 100%
   );
}

.bg-gradient-blueLight {
   background: rgb(0, 48, 96);
   background: linear-gradient(
      180deg,
      rgb(0, 48, 96) 0%,
      rgb(0, 95, 143) 48%,
      rgb(0, 146, 193) 100%
   );
}

.bg-gradient-green {
   background: rgb(0, 64, 50);
   background: linear-gradient(
      180deg,
      rgb(0, 64, 50) 0%,
      rgb(51, 106, 67) 48%,
      rgb(91, 142, 81) 100%
   );
}

.bg-gradient-red {
   background: rgb(71, 17, 48);
   background: linear-gradient(
      180deg,
      rgb(71, 17, 48) 0%,
      rgb(128, 54, 57) 48%,
      rgb(183, 91, 68) 100%
   );
}

.bg-gradient-grape {
   background: rgb(71, 18, 64);
   background: linear-gradient(
      180deg,
      rgb(71, 18, 64) 0%,
      rgb(121, 33, 84) 48%,
      rgb(168, 48, 102) 100%
   );
}
