.logo {
   position: fixed;
   width: 100%;
   top: 0px;
   right: 0px;
   z-index: 1;
   svg {
      padding: 20px 0 0;
      max-width: 167px;
      @media (min-width: map-get($grid-breakpoints, md)) {
         max-width: 226px;
      }
      @media (min-width: map-get($grid-breakpoints, xl)) {
         max-width: 264px;
      }
   }
}

#header {
   &.scroll {
      background-color: rgba(map-get($colors, five), 1);
      // box-shadow: 0px -12px 20px #000;
      border-bottom: 1px solid #e1e1e5;
   }
   position: fixed;
   width: 100%;
   margin: auto;
   z-index: 2;
   margin-left: auto;
   margin-right: auto;
   left: 0;
   right: 0;
   top: 0px;
   transition: all 0.5s ease-out;
   overflow: hidden;
   // padding: 10px 0;
   display: flex;
   height: 60px;
   align-items: center;
   border-bottom: 1px solid transparent;

   svg {
      width: 150px;
   }

   #header-inner {
      position: relative;
      height: 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      -webkit-transition: all 0.5s ease-out;
      -moz-transition: all 0.5s ease-out;
      -o-transition: all 0.5s ease-out;
      transition: all 0.5s ease-out;
      // padding-top: 35px;

      ul {
         li {
            display: inline-block;
            margin-left: 14px;
            margin-right: 14px;
            font-size: 16px;
            letter-spacing: 0.5px;
            line-height: normal;

            &:first-child {
               margin-left: 0;
            }

            @media (min-width: map-get($grid-breakpoints, lg)) {
               margin-left: 20px;
               margin-right: 20px;
               font-size: 18px;
            }
         }
      }
   }

   .main-menu {
      ul {
         margin-bottom: 0;

         .current-menu-item {
            a {
               text-decoration: underline;
            }
         }
      }
   }
}

@media (max-width: map-get($grid-breakpoints, md)) {
   .hamburger {
      display: block;
   }

   .main-menu {
      display: none;
   }
}
