.item-enter {
   opacity: 0;
   // transform: scale(0.9);
}
.item-enter-active {
   opacity: 1;
   // transform: translateX(0);
   transition: opacity 800ms, transform 800ms;
}
.item-exit {
   opacity: 1;
}
.item-exit-active {
   opacity: 0;
   // transform: scale(0.9);
   transition: opacity 800ms, transform 800ms;
}

// slow
//--------------------------------------------------------
.slow-enter {
   opacity: 0;
}
.slow-enter-active {
   opacity: 1;
   transition: opacity 7s;
}
.slow-exit {
   opacity: 1;
}
.slow-exit-active {
   opacity: 0;
   transition: opacity 1200ms;
}


.fade {
   position: relative;
   opacity: 0;
   z-index: -100;
   transition: opacity 12s;

   &.fadeOut {
      opacity: 0;
      z-index: -100;
      transition: opacity 0s;
   }

   &.fadeIn {
      opacity: 1;
      z-index: 2;
   }

   &.fadeInSoft {
      opacity: 1;
      z-index: 1;
      transition: opacity 3s;
   }
}