.hamburger {
   padding: 8px 0;
   display: inline-block;
   cursor: pointer;
   transition-property: opacity, filter;
   transition-duration: 0.15s;
   transition-timing-function: linear;
   color: inherit;
   text-transform: none;
   background-color: transparent;
   border: 0;
   margin: 0;
   overflow: visible;
   text-align: center;
   padding-top: 15px;
}

.hamburger:hover {
   opacity: 0.7;
}

.hamburger.active:hover {
   opacity: 0.7;
}

.hamburger.active .hamburger-inner,
.hamburger.active .hamburger-inner::before,
.hamburger.active .hamburger-inner::after {
   background-color: map-get($colors, base);
   transition: all 0.7s;
}

.hamburger-box {
   width: 25px;
   height: 24px;
   display: inline-block;
   position: relative;
}

.hamburger-inner {
   display: block;
   top: 50%;
   margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
   width: 25px;
   height: 3px;
   background-color: map-get($colors, base);
   border-radius: 4px;
   position: absolute;
   transition-property: transform;
   transition-duration: 0.15s;
   transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
   content: "";
   display: block;
}

.hamburger-inner::before {
   top: -8px;
}

.hamburger-inner::after {
   bottom: -8px;
}

.hamburger--squeeze .hamburger-inner {
   transition-duration: 0.075s;
   transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze .hamburger-inner::before {
   transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}

.hamburger--squeeze .hamburger-inner::after {
   transition: bottom 0.075s 0.12s ease,
      transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.active .hamburger-inner {
   transform: rotate(45deg);
   transition-delay: 0.12s;
   transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--squeeze.active .hamburger-inner::before {
   top: 0;
   opacity: 0;
   transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}

.hamburger--squeeze.active .hamburger-inner::after {
   bottom: 0;
   transform: rotate(-90deg);
   transition: bottom 0.075s ease,
      transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media (max-width: 600px) {
   .hamburger {
      display: block;
   }

   .desktop-nav {
      display: none;
   }

   nav {
      .desktop-nav {
      }
      .menu-hauptmenu-container {
         background-color: white;
      }

      ul {
         padding: 0;
         li {
            display: block;
            padding: 0;
            padding-top: 15px;
            padding-bottom: 15px;
            border-right: none;
         }
      }
   }
   .open-nav {
      display: block;
   }
}
