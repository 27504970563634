* {
   box-sizing: border-box;
}

html {
   scroll-behavior: smooth;
   background-color: black;
}

body {
   //when sidebar opened
   &.active {
      overflow: hidden;
   }
}

#wrapper {
   margin-top: 100px;
   transition: all 0.5s;
   min-height: 100vh;
   // overflow: hidden;

   &.wrapper--over {
      margin-top: 0;
   }
}

.be-stage {
   position: fixed;
   top: 0;
   right: 0;
   width: 100%;
   height: 100%;

   be-stage {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 100%;
   }

   canvas {
      height: 100% !important;
      width: 100% !important;
      display: block;
   }

   &.be-stage--loading {
      background-color: map-get($colors, base);
      background-image: url("data:image/svg+xml,%3Csvg width='38' height='38' viewBox='0 0 38 38' xmlns='http://www.w3.org/2000/svg' stroke='%23aaa'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(1 1)' stroke-width='2'%3E%3Ccircle stroke-opacity='.5' cx='18' cy='18' r='18'/%3E%3Cpath d='M36 18c0-9.94-8.06-18-18-18'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='1s' repeatCount='indefinite'/%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 40px;
      color: transparent !important;
   }
}

$nav_width: 25%;
.slider--bestage {
   .logo,
   .be-stage {
      width: calc(100% - #{$nav_width});
   }

   .slider {
      max-width: $nav_width;
      overflow: hidden;
   }
}




   .stickyNav {
      position: fixed;
      top:0;
      left:0;
      width: 100%;
      background-color: rgba(white, 0.8);
   }
