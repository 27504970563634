$sidebarPadding: 40px 0;

.sidebar {
   padding: 20px 30px;
   top: 0;
   right: -80vw;
   position: fixed;
   margin: 0;
   width: 50vw;
   max-width: 1000px;
   height: 100vh;
   background-color: white;
   transition: all 0.3s;
   display: flex;
   flex-direction: column;
   z-index: 2;
   overflow: scroll;

   .sidebar__close {
      position: absolute;
      top: 10px;
      left: 10px;
   }

   .sidebar__nav {
      padding: $sidebarPadding;
      ul {
         max-width: 80%;
         margin: auto;
         display: flex;
         flex-direction: row;
         position: relative;

         li {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            position: relative;

            * {
               position: relative;
               z-index: 3;
            }

            &:after {
               content: "";
               display: block;
               height: 100%;
               width: 40px;
               top: 0;
               left: 50%;
               position: absolute;
               background-color: white;
               z-index: 2;
               transform: translateX(-50%);
            }

            &:first-child {
               &:after {
                  width: 100%;
                  right: calc(50% - 20px);
                  transform: inherit;
                  left: inherit;
               }
            }

            &:last-child {
               &:after {
                  width: 100%;
                  left: calc(50% - 20px);
                  transform: inherit;
               }
            }
         }

         &:after {
            content: "";
            display: block;
            height: 1px;
            width: 100%;
            top: 50%;
            right: 0;
            position: absolute;
            transform: translateY(-50%);
            border-top: dashed 1px black;
            opacity: 0.2;
            z-index: 1;
         }
      }
   }

   // .sidebar__header {
   //    display: flex;
   //    padding: $sidebarPadding;
   // }
   .sidebar__body {
      // padding: 0 $sidebarPadding;
   }

   .sidebar__loading {
      min-height: 40vh;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: url("data:image/svg+xml,%3Csvg width='38' height='38' viewBox='0 0 38 38' xmlns='http://www.w3.org/2000/svg' stroke='%23aaa'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(1 1)' stroke-width='2'%3E%3Ccircle stroke-opacity='.5' cx='18' cy='18' r='18'/%3E%3Cpath d='M36 18c0-9.94-8.06-18-18-18'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='1s' repeatCount='indefinite'/%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 60px 60px;

      * {
         margin-bottom: 100px;
      }
   }

   .sidebar__footer {
      display: flex;
      align-items: center;
      margin-top: auto;
   }

   ul {
      padding: 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: auto;
      width: 100%;

      li {
         margin: 0 0 20px 0;
         padding: 0;
         list-style: none;
         width: 100%;
         text-align: left;
         display: flex;
         align-items: center;
      }
   }

   &.show {
      right: 0;
      box-shadow: 19px -12px 20px #000;
   }
}
