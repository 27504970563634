.icon {
   height: 40px;
   width: 40px;
   background-repeat: no-repeat;
   position: relative;
   border: none;
   background-color: transparent;
   display: flex;
   align-items: center;
   justify-content: center;
   background-position: center center;
   background-size: 50%;

   span {
      position: absolute;
      top: 0;
      right: 0;
      background-color: map-get($colors, primary);
      padding: 5px;
      display: inline-block;
      border-radius: 50%;
      height: 20px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
   }

   &.icon--small {
      height: 26px;
      width: 26px;
   }

   &.icon--big {
      height: 60px;
      width: 60px;
   }

   &.icon--cart {
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 16C16.5304 16 17.0391 16.2107 17.4142 16.5858C17.7893 16.9609 18 17.4696 18 18C18 18.5304 17.7893 19.0391 17.4142 19.4142C17.0391 19.7893 16.5304 20 16 20C14.89 20 14 19.1 14 18C14 16.89 14.89 16 16 16ZM0 0H3.27L4.21 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3C20 3.17 19.95 3.34 19.88 3.5L16.3 9.97C15.96 10.58 15.3 11 14.55 11H7.1L6.2 12.63L6.17 12.75C6.17 12.8163 6.19634 12.8799 6.24322 12.9268C6.29011 12.9737 6.3537 13 6.42 13H18V15H6C4.89 15 4 14.1 4 13C4 12.65 4.09 12.32 4.24 12.04L5.6 9.59L2 2H0V0ZM6 16C6.53043 16 7.03914 16.2107 7.41421 16.5858C7.78929 16.9609 8 17.4696 8 18C8 18.5304 7.78929 19.0391 7.41421 19.4142C7.03914 19.7893 6.53043 20 6 20C4.89 20 4 19.1 4 18C4 16.89 4.89 16 6 16ZM15 9L17.78 4H5.14L7.5 9H15Z' fill='%23ffffff'/%3E%3C/svg%3E");
      background-size: 25px;
      position: fixed;
      bottom: 40px;
      right: 40px;

      .icon__counter {
         position: absolute;
         top: -5px;
         right: 0px;
         background-color: white;

         border-radius: 50%;
         display: inline-block;
         height: 20px;
         line-height: 20px;
         width: 20px;
         padding: 0;
         font-size: 11px;
      }
   }

   &.icon--circle {
      border-radius: 50%;
   }

   &.disabled {
      opacity: 0.2;
   }

   &.icon--spinner {
      background-image: url("data:image/svg+xml,%3Csvg width='38' height='38' viewBox='0 0 38 38' xmlns='http://www.w3.org/2000/svg' stroke='%23aaa'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(1 1)' stroke-width='2'%3E%3Ccircle stroke-opacity='.5' cx='18' cy='18' r='18'/%3E%3Cpath d='M36 18c0-9.94-8.06-18-18-18'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='1s' repeatCount='indefinite'/%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
   }

   &.icon--minor {
      background-color: map-get($colors, greyDark);
      width: 30px;
      height: 30px;
      line-height: 30px;
   }

   &.icon--rounded {
      border-radius: 4px;
   }

   &.icon--arrow-right {
      background-image: url("data:image/svg+xml,%3Csvg width='17' height='29' viewBox='0 0 17 29' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.73926 0.933411L16.369 13.5257C16.5189 13.6756 16.6248 13.838 16.6868 14.0129C16.7498 14.1878 16.7813 14.3752 16.7813 14.575C16.7813 14.7749 16.7498 14.9623 16.6868 15.1372C16.6248 15.3121 16.5189 15.4745 16.369 15.6244L3.73926 28.2541C3.38948 28.6039 2.95225 28.7788 2.42757 28.7788C1.90289 28.7788 1.45317 28.5914 1.0784 28.2167C0.703627 27.8419 0.516243 27.4047 0.516243 26.905C0.516243 26.4053 0.703627 25.968 1.0784 25.5933L12.0966 14.575L1.0784 3.5568C0.728615 3.20701 0.553721 2.77628 0.553721 2.2646C0.553721 1.75191 0.741107 1.30818 1.11588 0.933411C1.49065 0.558642 1.92788 0.371256 2.42757 0.371257C2.92726 0.371257 3.36449 0.558642 3.73926 0.933411Z' fill='%23000000'/%3E%3C/svg%3E");
      background-size: 25%;
   }
   &.icon--arrow-left {
      background-image: url("data:image/svg+xml,%3Csvg width='17' height='29' viewBox='0 0 17 29' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.73926 0.933411L16.369 13.5257C16.5189 13.6756 16.6248 13.838 16.6868 14.0129C16.7498 14.1878 16.7813 14.3752 16.7813 14.575C16.7813 14.7749 16.7498 14.9623 16.6868 15.1372C16.6248 15.3121 16.5189 15.4745 16.369 15.6244L3.73926 28.2541C3.38948 28.6039 2.95225 28.7788 2.42757 28.7788C1.90289 28.7788 1.45317 28.5914 1.0784 28.2167C0.703627 27.8419 0.516243 27.4047 0.516243 26.905C0.516243 26.4053 0.703627 25.968 1.0784 25.5933L12.0966 14.575L1.0784 3.5568C0.728615 3.20701 0.553721 2.77628 0.553721 2.2646C0.553721 1.75191 0.741107 1.30818 1.11588 0.933411C1.49065 0.558642 1.92788 0.371256 2.42757 0.371257C2.92726 0.371257 3.36449 0.558642 3.73926 0.933411Z' fill='%23000000'/%3E%3C/svg%3E");
      background-size: 25%;
      transform: rotate(-180deg);
   }
}
