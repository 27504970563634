//*******************************************************
// colors
//*******************************************************

$colors: (
   base: rgb(87, 87, 87),
   primary: #af92f2,
   secondary: #d1ddbc,
   third: #bae5ee,
   four: #bae5ee,
   five: #bae5ee,
   white: #fff,
   transparent: rgba(345, 345, 345, 0.2),
   danger: #d42727,
   blue: rgb(35, 63, 214),
   purple: rgb(144, 54, 190),
   red: rgb(210, 46, 78),
   brown: rgb(231, 111, 43),
   green: rgb(110, 175, 62),
   blue_ocean: rgb(0, 186, 207),
   black: black,
   grey: #f2f2f2,
   seamless: #f9f9f9,
);

//*******************************************************
// typo
//*******************************************************

$typo: (
   sm: (
      1: (
         font-size: 32px,
         line-height: 42px,
      ),
      2: (
         font-size: 26px,
         line-height: 35px,
      ),
      3: (
         font-size: 22px,
         line-height: 26px,
      ),
      4: (
         font-size: 18px,
         line-height: 24px,
         letter-spacing: 1.2pt,
      ),
      body: (
         font-size: 14px,
         line-height: 19px,
      ),
      small: (
         font-size: 10px,
         line-height: 13px,
      ),
      micro: (
         font-size: 11px,
         line-height: 13px,
      ),
   ),
   md: (
      1: (
         font-size: 50px,
         line-height: 60px,
      ),
      2: (
         font-size: 40px,
         line-height: 50px,
      ),
      3: (
         font-size: 32px,
         line-height: 40px,
      ),
      p3: (
         font-size: 27px,
         line-height: 35px,
      ),
      p2: (
         font-size: 18px,
         line-height: 24px,
      ),
      body: (
         font-size: 16px,
         line-height: 26px,
      ),
      small: (
         font-size: 14px,
         line-height: 18px,
      ),
   ),
);

//*******************************************************
// breakingpoints
//*******************************************************

$grid-breakpoints: (
   sm: 0,
   smmd: 500px,
   md: 768px,
   lg: 992px,
   xl: 1440px,
   // xxl: 1600px,
   // max: 2560px,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
);

$container-max-widths: (
   sm: 90%,
   xl: 1400px,
);

//*******************************************************
// spacing
//*******************************************************

$spaces: (
   sm: (
      200: 200px,
      120: 120px,
      100: 100px,
      80: 80px,
      60: 60px,
      50: 50px,
   ),
);

//*******************************************************
// buttons
//*******************************************************

$buttons: (
   sm: (
      1: (
         line-height: 55px,
         height: 55px,
         font-size: 16px,
         padding: 0 2em,
      ),
      2: (
         line-height: 30px,
         height: 30px,
         font-size: 13px,
         padding: 0 2em,
      ),
      3: (
         line-height: 25px,
         height: 30px,
         font-size: 12px,
         padding: 2px 10px,
      ),
      4: (
         line-height: 21px,
         height: 25px,
         font-size: 12px,
         padding: 2px 3px,
      ),
   ),
   md: (
      1: (
         line-height: 51px,
         height: 51px,
         font-size: 15px,
         font-weight: 500,
      ),
      2: (
         line-height: 36px,
         height: 36px,
         font-size: 16px,
      ),
   ),
);

//*******************************************************
// times
//*******************************************************

$times: (
   fast: 0.3s,
   medium: 0.5s,
   slow: 1s,
);
