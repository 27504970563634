/*loop over all breaking points*/
@each $query_name, $query_value in $grid-breakpoints {
   //if breaking point not empty (= false)
   @if map-get($buttons, $query_name) != null {
      @each $name, $value in map-deep-get($buttons, $query_name) {
         @media (min-width: map-get($grid-breakpoints, $query_name)) {
            .btn--#{$name} {
               @if $value != null {
                  font-size: map-deep-get($value, font-size);
               }
               @if $value != null and map-deep-get($value, line-height) != null
               {
                  line-height: map-deep-get($value, line-height);
               }
               @if $value != null and map-deep-get($value, height) != null {
                  height: map-deep-get($value, height);
               }
               @if $value !=
                  null and
                  map-deep-get($value, letter-spacing) !=
                  0px and
                  map-deep-get($value, letter-spacing) !=
                  null
               {
                  letter-spacing: map-deep-get($value, letter-spacing);
               }
               @if $value != null and map-deep-get($value, padding) != null {
                  padding: map-deep-get($value, padding);
               }
            }
         }
      }
   }
}

.btn {
   flex-shrink: 0;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   border-radius: 10px;
   background-color: rgba(map-get($colors, purple), 0.5);
   text-align: center;
   letter-spacing: 1.2pt;
   font-family: "Bureau Grot Med";
   font-display: swap;
   font-style: normal;
   font-weight: normal;
   transition: all map-get($times, fast);
   border: 1px solid white;
   -moz-appearance: none;
   display: inline-block;
   color: white;
   text-transform: uppercase;
   cursor: pointer;

   svg {
      max-width: 100%;
      max-height: 80%;
   }

   &:hover {
      background-color: rgba(map-get($colors, purple), 0.7);
      color: map-get($colors, white);
   }

   &.btn--loading {
      background-image: url("data:image/svg+xml,%3Csvg width='38' height='38' viewBox='0 0 38 38' xmlns='http://www.w3.org/2000/svg' stroke='%23aaa'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(1 1)' stroke-width='2'%3E%3Ccircle stroke-opacity='.5' cx='18' cy='18' r='18'/%3E%3Cpath d='M36 18c0-9.94-8.06-18-18-18'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='1s' repeatCount='indefinite'/%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 70% 70%;
      color: transparent !important;
   }

   &.btn--onTop {
      position: absolute;
      z-index: 2;
      transform: translate(-50%, -50%);
   }

   &.btn--circle {
      border-radius: 50%;
      padding: 0;
      width: 140px;
      height: 140px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.btn--play {
         transform: scale(0.95);

         &:hover {
            transform: scale(1);
            
         }
      }

      &.btn--circle-small {
         width: 90px;
         height: 90px;
         font-size: 0.8em;
         text-transform: none;
      }

      &.btn--circle-micro {
         width: 70px;
         height: 70px;
         font-size: 0.8em;
         text-transform: none;
      }
   }

   &.btn--icon {
      width: 40px;
      height: 40px;
      font-size: 0.8em;
      text-transform: none;
      background-color: white;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      overflow: visible;

      &:hover {
         opacity: 0.7;
      }

      &:active {
         top: 1px;
      }

      &.rotate-305 {
         transform: rotate(315deg);
      }

      &.rotate-260 {
         transform: rotate(270deg);
      }

      &.rotate-180 {
         transform: rotate(180deg);
      }

      &.rotate-135 {
         transform: rotate(135deg);
      }

      &.rotate-90 {
         transform: rotate(90deg);
      }

      span {
         position: absolute;
         bottom: -25px;
         font-size: 10px;
         color: white;
         left: 50%;
         transform: translateX(-50%);
         text-transform: uppercase;
      }
   }

   &.btn--delete {
      &:hover {
         background-color: map-get($colors, danger) !important;
         overflow: hidden;
         position: relative;

         &:after {
            content: "löschen";
            display: block;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            position: absolute;
            background-color: map-get($colors, danger) !important;
         }
      }
   }

   &:disabled {
      opacity: 0.4;
      cursor: wait;
   }

   &.btn--visited {
      background-color: map-get($colors, transparent) !important
   }
}

.texture-reset {
   padding-bottom: 100%;
   background-color: black;
   position: relative;
   overflow: hidden;
   &:after {
      content: '';
      display:block;
      height: 1px;
      width:150%;
      top:0;
      left:0;
      position: absolute;
      background-color: red;
      transform: rotate(45deg);
      transform-origin: left top;
   }
}