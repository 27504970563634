//*******************************************************
// modal
//*******************************************************

.modal {
   position: fixed;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   opacity: 0;
   z-index: -100;
   justify-content: center;
   align-items: center;
   background-color: rgba(0, 0, 0, 0.7);
   transition: opacity map-get($times, medium);
   display: flex;

   &.modal--loading {
      .modal__inner {
         &:after {
            opacity: 1;
            z-index: 1;
         }
      }
   }

   &.modal--opened {
      z-index: 100;
      opacity: 2;

      .modal__inner {
         opacity: 1;
         transform: translateY(0);
         transition: all map-get($times, medium) ease-in-out 0.5s;
      }
   }

   &.modal--closed {
      position: absolute;
      z-index: -100;
      top: -10000px;
      
   }

   .modal__inner {
      opacity: 0;
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: white;
      display: flex;
      justify-content: space-between;
      transform: translateY(-200px);

      // for loading
      &:after {
         transition: opacity map-get($times, fast);
         z-index: -1;
         content: "";
         display: block;
         height: 100%;
         width: 100%;
         top: 0;
         left: 0;
         position: absolute;
         opacity: 0;
         background-color: white;
         // background-image: url("../../src/img/loader.svg");
         background-repeat: no-repeat;
         background-position: center center;
         background-size: 40px;
      }

      .modal__content {
         width: 100%;
         height: 100%;
      }
   }
   .modal__close-btn {
      width: 32px;
      height: 32px;
      position: absolute;
      top: 7px;
      right: 7px;
      cursor: pointer;
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none%3Bstroke:%23444%3Bstroke-linecap:round%3Bstroke-linejoin:round%3Bstroke-width:2px%3B%7D%3C/style%3E%3C/defs%3E%3Ctitle/%3E%3Cg id='cross'%3E%3Cline class='cls-1' x1='7' x2='25' y1='7' y2='25'/%3E%3Cline class='cls-1' x1='7' x2='25' y1='25' y2='7'/%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      -moz-appearance: none;
      border: none;
      background-color: transparent;
      z-index: 1;
   }

   &.modal--narrow {
      .modal__inner {
         max-width: 700px;
         max-height: 90%;
         border-radius: 20px;
         padding: 40px;
         background-color: rgba(white, 0.8);
         height: auto;
         overflow: scroll;
      }
   }

   &.modal--small {
      .modal__inner {
         max-width: 350px;
         max-height: 90%;
         border-radius: 20px;
         padding: 40px;
         background-color: rgba(white, 0.8);
         height: auto;
         overflow: scroll;

         .react-colorful {
            width: 300px;
            height: 300px;
         }
      }
   }
}

.opacity {
   &:hover {
      opacity: 0.8;
   }
}
