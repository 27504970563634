//*******************************************************
// Progress
//*******************************************************

.progress {
   height: 20px;
   background-color: rgba(map-get($colors, purple), 0.5);
   border-radius: 15px;
   position: relative;


   &.progress--slider {
      position: absolute;
      bottom:10px;
      right:10px;
      z-index: 2;
      width: 30%;
      min-width: 200px;
   }

   .progress__fill {
      position: absolute;
      inset: 2px 2px 2px;
      background-color: white;
      border-radius: 15px;
      width: 50%;
      transition: all 0.3s;
   }
}

//*******************************************************
// Slider
//*******************************************************

.slider {
   min-height: 100vh;
   position: relative;

   &.slider--onTop {
      z-index: 3;
   }

   .slider__nav {
      position: fixed;
      top: 0;
      right: 0;
      padding: 5px;
      z-index: 3;
   }

   .slider__item {
      min-height: 100vh;
      min-width: 80vw;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      transition: all 0.3s;

      &.slider__item--active {
         opacity: 1;
         z-index: 2;
      }

      .slider__item-inner {
         min-width: 80vw;
         flex-direction: column;
         justify-content: center;
      }
   }
}

//*******************************************************
// offtext
//*******************************************************

.offtext {
   position: fixed;
   top: 0;
   right: 0;
   min-height: 100vh;
   background-color: rgba(white, 0.7);
   z-index: 2;
   min-width: 40vw;
   transition: all 0.3s;
   transform: translateX(max(40vw));

   &.offtext--show {
      transform: translateX(0vw);
   }
}

//*******************************************************
// Slider
//*******************************************************

:root {
   --main-color: rgb(180, 180, 180);
   --main-color-hover: rgb(104, 117, 217, 0.2);
   --track-color: #eee;
   --mark-color: #aaa;
}

.customSlider {
   /* Optional: Only If you want to center your slider in the page */
   margin: auto;
}

.customSlider-track {
   /* Top value to align your track to the center of your thumb */
   top: 20px;
   /* thickness of the track */
   height: 20px;
   /* default color of your track */
   background: var(--track-color);
   border-radius: 10px;
}

.customSlider-track.customSlider-track-0 {
   /* color of the track before the thumb */
   background: var(--main-color);
}

.customSlider-thumb {
   cursor: pointer;
   /*color for the thumb */
   background: white;
   /* shape of the thumb: circle */
   width: 60px;
   height: 60px;
   border-radius: 100%;
   /* remove default outline when selected */
   outline: none;
   box-shadow: 0 0 15px -4px #000;
}

.customSlider-thumb:hover {
   box-shadow: none;
}

.customSlider-mark {
   cursor: pointer;
   top: 6px;
   width: 1.5px;
   height: 8px;
   background-color: var(--mark-color);
}

.horizontalLine {
   background-color: #ccc;
   height: 1px;
   width: 100%;
}

//Accordion
.description {
   font-size: 12px;
   padding-bottom: 12px;
}

.fixed-sidebar {
   height: 100vh; /* Set the desired height of the sidebar */
   position: fixed;
   top: 0;
   left: 0;
   overflow-y: auto; /* Enable vertical scrolling */
   background-color: #f2f2f2;
   display: flex;
   flex-direction: column;
}

//*******************************************************
// Toggle switch
//*******************************************************
.switch {
   position: relative;
   display: inline-block;
   width: 60px;
   height: 30px;

   input {
      display: none;
   }

   input:checked + .toggleSlider {
      background-color: #2196f3;
   }
   
   input:focus + .toggleSlider {
      box-shadow: 0 0 1px #2196f3;
   }
   
   input:checked + .toggleSlider:before {
      transform: translateX(30px);
   }
}


/* The slider */
.toggleSlider {
   position: absolute;
   cursor: pointer;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-color: #ccc;
   -webkit-transition: 0.4s;
   transition: 0.4s;

   .toggleSlider__labels {
      width: 100%;
      display: block;
      height: 100%;

      &:after {
         content: 'off';
         display:block;
         height: 100%;
         width:100%;
         top:1px;
         left:6px;
         position: absolute;
         font-size: 11px;
      }

      &:before {
         content: 'on';
         display:block;
         height: 100%;
         width:100%;
         top:1px;
         right:7px;
         position: absolute;
         font-size: 11px;
         text-align: right;
      }
   }
}

.toggleSlider:before {
   position: absolute;
   content: "";
   height: 26px;
   width: 26px;
   left: 2px;
   bottom: 2px;
   background-color: white;
   -webkit-transition: 0.4s;
   transition: 0.4s;
}



/* Rounded sliders */
.toggleSlider.round {
   border-radius: 34px;
}

.toggleSlider.round:before {
   border-radius: 50%;
}

// ARIEN SUBTITLES
//--------------------------------------------------------

.subtitles {
   max-height: 35vh;
   overflow: scroll;

   @media (min-width:map-get($grid-breakpoints, 1600)){
      max-height: 45vh;
   }
}

.gallery {
   .gallery__image {
      height: 300px;
      object-fit: cover;
      width: 100%;
   }
}

//*******************************************************
// consent
//*******************************************************
.consent {
   position: fixed;
   bottom: 0px;
   left:0;
   width: 100%;
   background-color: rgba(black, 0.7);
   color: white;
   z-index: 10;
   padding: 20px;
   display: flex;

}

//*******************************************************
// service nav
//*******************************************************

.service {
   position: absolute;
   bottom: 0;
   left:0;
   width: 100%;
   color: map-get($colors, purple);   
   display: flex;
   align-items: center;
   justify-content: start;
   z-index: 2;
   padding: 10px;
}