//*******************************************************
// spacing
//*******************************************************

   /*loop over all breaking points*/
   @each $query_name, $query_value in $grid-breakpoints {    
      
    //if breaking point not empty
      @if map-get($spaces, $query_name) != null {            
         @if $query_value == 0 {
            @debug $query_value;
            @each $name, $value in map-get($spaces, $query_name) {
               @if $name != null {
               .mb--#{$name} {
                  margin-bottom: $value !important;
               }
               .mt--#{$name} {
                  margin-top: $value !important;
               }

               .p--#{$name} {
                  padding: $value;
               }
               .pl--#{$name} {
                  padding-left: $value !important;
               }
               .pr--#{$name} {
                  padding-right: $value !important;
               }
               .pt--#{$name} {
                  padding-top: $value !important;
               }
               .pb--#{$name} {
                  padding-bottom: $value !important;
               }
            }
          }
      } @else {
         @debug $query_value;
          @media (min-width: $query_value){
             @each $name, $value in map-get($spaces, $query_name) {
                @if $name != null {
                     .pt--#{$name} {
                        padding-top: $value !important;
                     }
                     .pb--#{$name} {
                        padding-bottom: $value !important;
                     }
                     .mt--#{$name} {
                        margin-top: $value !important;
                     }
                     .mb--#{$name} {
                        margin-bottom: $value !important;
                     }


                     .mb-#{$query_name}-#{$name} {
                        margin-bottom: $value !important;
                     }

                     .p-#{$query_name}--#{$name} {
                        padding: $value;
                     }

                     .pl-#{$query_name}--#{$name} {
                        padding-left: $value;
                     }

                     .pt-#{$query_name}--#{$name} {
                        padding-top: $value !important;
                     }

                     .pb-#{$query_name}--#{$name} {
                        padding-bottom: $value !important;
                     }
                }
             }
          }
       }
    } 
 }