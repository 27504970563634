@use "sass:math";
* {
   box-sizing: border-box;
}

html,
body {
   margin: 0;
   padding: 0;
}

be-stage {
   display: block;
}


@import "variables"; /* to override bootstraps values we need to be before the bootstrap import */
@import "../../node_modules/bootstrap/scss/bootstrap-grid";
@import "../../node_modules/swiper/swiper-bundle.css";
@import "functions";
// @import "fonts";

@import "./utilities/spacing";
@import "./utilities/backgrounds";
@import "./utilities/helpers";
@import "./utilities/normalize";
@import "./utilities/media";
@import "./utilities/animations";
@import "./utilities/borders";

@import "./forms/forms";

@import "./typo/buttons";
@import "./typo/typo";
@import "./typo/icons";

@import "./layout/footer";
@import "./layout/header";
@import "./layout/navigation";
@import "./layout/sidebar";
@import "./layout/structure";
@import "./layout/ui";
@import "./layout/mbb";
@import "./layout/SlideEditor.scss";

@import "./modules/modal";
