//*******************************************************
// sizing
//*******************************************************

body {
   font-family: "Bureau Grot Lt";
   font-display: swap;
   font-style: normal;
   font-weight: normal;
   color: map-get($colors, base);

   @each $query_name, $query_value in $grid-breakpoints {
      @if map-deep-get($typo, $query_name) != null {
         @media (min-width: map-get($grid-breakpoints, $query_name)) {
            @if map-deep-get($typo, $query_name, body, font-size) != null {
               font-size: map-deep-get($typo, $query_name, body, font-size);
            }

            @if map-deep-get($typo, $query_name, body, line-height) != null {
               line-height: map-deep-get($typo, $query_name, body, line-height);
            }

            @if map-deep-get($typo, $query_name, body, letter-spacing) != null {
               letter-spacing: map-deep-get(
                  $typo,
                  $query_name,
                  body,
                  letter-spacing
               );
            }
         }
      }
   }
}

.bureaugrotblack {
   font-family: "Bureau Grot Bla";
   font-display: swap;
   font-style: normal;
   font-weight: normal;
 }
 .bureaugrotmedium {
   font-family: "Bureau Grot Med";
   font-display: swap;
   font-style: normal;
   font-weight: normal;
 }
 .bureaugrotlight {
   font-family: "Bureau Grot Lt";
   font-display: swap;
   font-style: normal;
   font-weight: normal;
 }

p,
ul {
   font-weight: normal;
   margin-top: 0;
   margin-bottom: 30px;
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
   font-family: "Bureau Grot Bla";
   font-display: swap;
   font-style: normal;
   font-weight: normal;
   margin-top: 0;
   font-stretch: normal;   
   margin-bottom: 20px;
}

// h2,
// .h2{
//    font-size: 18px !important;
//    line-height: 24px !important;
//    font-weight: 700;
//    letter-spacing: 1.2pt;
// }

/*loop over all breaking points*/
@each $query_name, $query_value in $grid-breakpoints {
   //if breaking point not empty
   @if map-deep-get($typo, $query_name) != null {
      //loop over all font types (1, 2, 3, p, small) in the breaking point
      @each $name, $value in map-deep-get($typo, $query_name) {
         @media (min-width: map-get($grid-breakpoints, $query_name)) {
            .h#{$name} {
               @if $value != null {
                  font-size: map-deep-get($value, font-size);
               }

               @if $value != null and map-deep-get($value, line-height) != null
               {
                  line-height: map-deep-get($value, line-height);
               }

               @if $value !=
                  null and
                  map-deep-get($value, letter-spacing) !=
                  0px and
                  map-deep-get($value, letter-spacing) !=
                  null
               {
                  letter-spacing: map-deep-get($value, letter-spacing);
               }
            }
         }
      }
   }
}

//*******************************************************
// Text styles
//*******************************************************

a {
   text-decoration: none;
}

.text-center {
   text-align: center !important;
}

.text-right {
   text-align: right !important;
}

.text-left {
   text-align: left !important;
}

.text-uppercase {
   text-transform: uppercase;
}

.text-capitalize {
   text-transform: capitalize;
}

.text-underline {
   text-decoration: underline;
}

.text-no-underline {
   text-decoration: none;
}

strong,
.strong {
   font-weight: 700;

   p,
   ul {
      font-weight: 700;
   }
}

.font-body {
   font-family: "Lato";
}

//*******************************************************
// lists
//*******************************************************

.list {
   &.list--inline {
      padding: 0;
      margin: 0;

      li {
         margin-bottom: 10px;
         @media (min-width: map-get($grid-breakpoints, sm)) {
            display: inline-block;
         }
         list-style-type: none;
      }
   }

   &.list--reset {
      padding: 0;
      margin: 0;
      li {
         list-style-type: none;
         padding: 0;
         margin: 0;
      }
   }
}

//*******************************************************
// decorations
//*******************************************************

.separator {
   padding-top: 15px;
   border-top: 4px solid black;
}

.separator--bottom {
   padding-bottom: 15px;
   border-bottom: 2px solid black;
}

.text-circle {
   background-color: map-get($colors, secondary);
   border-radius: 50%;
   height: 300px;
   width: 300px;
   margin: 40px auto 40px auto;
   text-align: center;
   display: flex;
   align-items: center;
   justify-content: center;
   position: relative;

   .text-circle__inner {
      padding: 40px 40px 0 40px;

      p {
         font-size: 12px;
         line-height: 14px;
         font-weight: bold;
      }
   }

   .text-circle--small {
      height: 150px;
      width: 150px;
      background-color: white;
      position: absolute;
      bottom: -140px;
      right: -20px;

      .text-circle__inner {
         padding: 10px 15px 0 15px;
      }

      p {
         font-weight: normal;
         font-size: 10px;
         line-height: 13px;
         margin-bottom: 10px;
      }
   }
}

.summary {
   padding-top: 1em;
   padding-bottom: 1em;
   border-bottom: 1px solid map-get($colors, bgBase);
   display: flex;

   span {
      &:first-child {
         margin-right: auto;
      }
      &:last-child {
         margin-left: auto;
      }
   }

   &.summary--total {
      font-weight: 700;
   }
}

//*******************************************************
// colors
//*******************************************************

@each $name, $value in $colors {
   .text-#{$name} {
      color: $value !important;

      a {
         color: $value;
      }
   }
}

//*******************************************************
// links
//*******************************************************

a {
   display: inline-block;
   color: map-get($colors, base);

   &:hover {
      color: map-get($colors, purple);
   }
}

.headlines-shadow {
   .shadow,
   .h3,
   .h2 {
      text-shadow: 0px 1px 3px #484848;
   }
}

.reset {
   text-shadow: none !important;
}
