//*******************************************************
// Accordion
//*******************************************************

.accordion__item {
   border-top: 1px solid map-get($colors, grey);

   &:last-child {
      border-bottom: 1px solid map-get($colors, grey);
   }
}
.accordion__itemContent {
   max-height: 0;
   transition: all 0.3s;
   overflow: hidden;

   &.accordion__itemContent--opened {
      max-height: 1500px;
   }

   .accordion__subItem {
      background-color: map-get($colors, seamless);
      padding: 5px;
      margin-bottom: 2px;

      &.accordion__subItem--active{
         background-color: map-get($colors, primary);
      }
   }
}

//*******************************************************
// CLONE SETTINGS
//*******************************************************

.slideEditor__cloneSettings {
   position: fixed;
   top: 30px;
   right: 30px;
   display: flex;
   transition: all 0.5s;

   .slideEditor__cloneSettings-scale,
   .slideEditor__cloneSettings-move {
      display: none;
   }

   &.slideEditor__cloneSettings--moveActive {
      .buttons__wrapper--move {
         padding-bottom: 190px;
         position: relative;

         .slideEditor__cloneSettings-move {
            position: absolute;
            bottom: 0px;
            right: 0;
            width: 152px;
            height: 152px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
         }
      }

      .slideEditor__cloneSettings-move {
         display: flex;
      }
   }

   &.slideEditor__cloneSettings--scaleActive {
      .buttons__wrapper--scale {
         padding-bottom: 190px;
         position: relative;

         .slideEditor__cloneSettings-scale {
            position: absolute;
            bottom: 0px;
            right: 0;
            width: 100%;
            height: 152px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
         }
      }

      .slideEditor__cloneSettings-scale {
         display: flex;
      }
   }
}

//*******************************************************
// Camera settings
//*******************************************************

.slideEditor__cameraSettings {
   position: fixed;
   bottom: 30px;
   right: 30px;
   display: flex;
   transition: all 0.5s;

   flex-direction: column;
}
