footer{
    ul{
        li{
            list-style:none;
            margin-bottom: 12px;
        }
    }
    svg{
        width:150px;
    }
.footer-inner {
 
}

}