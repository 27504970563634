.circle {
   border-radius: 50%;
   overflow: hidden;
}

.reverse {
   flex-direction: row-reverse;
}

.loading {
   position: relative;

   &:after {
      content: "";
      display: block;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      position: absolute;
      background-image: url("data:image/svg+xml;base64,PCEtLSBCeSBTYW0gSGVyYmVydCAoQHNoZXJiKSwgZm9yIGV2ZXJ5b25lLiBNb3JlIEAgaHR0cDovL2dvby5nbC83QUp6YkwgLS0+Cjxzdmcgd2lkdGg9IjM4IiBoZWlnaHQ9IjM4IiB2aWV3Qm94PSIwIDAgMzggMzgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgc3Ryb2tlPSIjYWFhIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSAxKSIgc3Ryb2tlLXdpZHRoPSIyIj4KICAgICAgICAgICAgPGNpcmNsZSBzdHJva2Utb3BhY2l0eT0iLjUiIGN4PSIxOCIgY3k9IjE4IiByPSIxOCIvPgogICAgICAgICAgICA8cGF0aCBkPSJNMzYgMThjMC05Ljk0LTguMDYtMTgtMTgtMTgiPgogICAgICAgICAgICAgICAgPGFuaW1hdGVUcmFuc2Zvcm0KICAgICAgICAgICAgICAgICAgICBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iCiAgICAgICAgICAgICAgICAgICAgdHlwZT0icm90YXRlIgogICAgICAgICAgICAgICAgICAgIGZyb209IjAgMTggMTgiCiAgICAgICAgICAgICAgICAgICAgdG89IjM2MCAxOCAxOCIKICAgICAgICAgICAgICAgICAgICBkdXI9IjFzIgogICAgICAgICAgICAgICAgICAgIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIi8+CiAgICAgICAgICAgIDwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==");
      background-position: center center;
      background-size: 40px;
      background-repeat: no-repeat;
   }
}

.w-100 {
   width: 100%;
}
.h-100 {
   height: 100%;
}

.mh-100vh {
   min-height: 100vh;
}

.mw-100vw {
   min-width: 100vw;
}

.mh-30vh {
   min-height: 30vh;
}

.w-100px {
   width: 100px;
}

.maxw-100per {
   max-width: 100%;
}

.minw-500 {
   min-width: 500px;
}

.w-500 {
   width: 500px;
}

.animate {
   transition: all 0.5s;
   opacity: 1;

   &.disappear {
      opacity: 0;
   }
}

.position-relative {
   position: relative;
}

.switch-button {
   &:after {
      content: attr(data-default);
   }
   &.active {
      &:after {
         content: attr(data-switch);
      }
   }
}

.cursor-pointer {
   cursor: pointer;
}

.border-rounded {
   border-radius: 10px;
}

.overflow-hidden {
   overflow: hidden;
}

.overflow-visible {
   overflow: visible !important;
}

.transition {
   transition: all 2s;
}

.position-absolute {
   position: absolute;
   top: 0;
   left: 0;
   z-index: 10;
}

.object-fit-cover {
   object-fit: cover;
}

.visibility-hidden {
   visibility: hidden;
   z-index: -10;
   position: relative;
}
