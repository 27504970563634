svg,
img {
   max-width: 100%;
   display: block;
   height: auto;
}

figure {
   margin: 0px;
}

video,
audio {
   width: 100%;
}

//*******************************************************
//
//*******************************************************

video,
img {
   &.full {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: bottom;
      position: absolute;
      top: 0;
      left: 0;
   }
}

//*******************************************************
// backgrounds
//*******************************************************

.bg-cover {
   background-size: cover;
}
